import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { graphql, compose } from 'react-apollo';
import debounce from 'lodash/debounce';
import { showConfirm, hideConfirm } from '../../actions/confirmActions';
import { Field, reduxForm, change } from 'redux-form';
import { loader } from 'graphql.macro'; // new
import { Tables } from '../../defTables';
import { fetchQl } from '../../apolloClient';
import defQls from '../../defQls';
import {
  processError,
  errorTranslate,
  checkLoadCrud,
  cleanFilters,
  getFilters,
  preSubmitValidation,
  submitValues,
  deleteRecord,
  uuid,
  getRecordFromOptionsIndirectOnFilter,
  resolveLink,
  getListviewFields,
} from '../../utils/helper';

import ShowIcon from '../icons/ShowIcon';
import PageHeader from '../PageHeader';

import CrudToolBar from '../CrudToolBar';
import { CrudNav } from '../CrudNav';
import CrudFilterOrderBar from '../CrudFilterOrderBar';
import IconLoading from '../icons/IconLoading';
import {
  MsgError,
  ListDataRercord,
  ListDataHeader,
  PageSettings,
} from '../ListData';
import { getInputValue } from '../../utils/commonutils';
import Labels from './../mailing/Labels';
import MailCompose from './../mailing/MailCompose';

const QUERY = loader('./CustomerListPage.query.gql'); // new

const crudCode = {};

const templates = [];

const tableCrud = 'customer';
const action = 'list';
const nameForm = '';
const qlName = 'crud_list_' + tableCrud;
const formFilterName = 'listFiltercustomer';
const formPagerName = 'customerPager';
let table = Tables[tableCrud];

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      crudTable: tableCrud,
      mainForm: formFilterName,
      listMode: 'rows',
      counter: 0,
      dialogOpened: {},
      date: Date.now,
      actionsave: 'list',
      parentid:
        this.props.match.params && this.props.match.params.parentid
          ? this.props.match.params.parentid
          : '',
      id: '',
      listviewFields: {},
      pagesSettings: [],
      pageSettingId: null,
      visibleListViewFields: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps &&
      prevProps[qlName] &&
      prevProps[qlName].loading === true &&
      this.props &&
      this.props[qlName] &&
      this.props[qlName].loading === false &&
      this.props[qlName].getPagesSetting
    ) {
      // execute only one time
      const { listviewFields, pagesSettings, pageSettingId } =
        getListviewFields(this.props[qlName], this.state);
      if (listviewFields) {
        this.setState({
          listviewFields,
          pagesSettings,
          pageSettingId,
        });
      }
    }
  }

  onChangePageSettingId = async (e) => {
    const pageSettingId = e.target.value;
    this.setState({
      pageSettingId,
    });
    const pagesSettings = this.state.pagesSettings;
    for (let r = 0; r < pagesSettings.length; r++) {
      if (pagesSettings[r].id === pageSettingId) {
        // very important to clone object, and dont let it by reference, this produces
        // changes in pagesSettings and not in listviewFields
        const listFieldsClone = JSON.parse(
          JSON.stringify(pagesSettings[r].fields)
        );
        this.updatePageSettingsFields(listFieldsClone);
      }
    }
  };

  updatePageSetting = (pageSettingId, action, values = {}) => {
    // update just states
    const pagesSettings = this.state.pagesSettings;
    // case too for add default pagesettings (name empty)
    if (action === 'delete' || action === 'afterSaveUpdate') {
      let rowFound = -1;
      for (let r = 0; r < pagesSettings.length; r++) {
        if (pagesSettings[r].id === pageSettingId) {
          rowFound = r;
          break;
        }
      }
      if (action === 'delete') {
        pagesSettings.splice(rowFound, 1);
        this.setState({
          pagesSettings,
          pageSettingId: pagesSettings[0].id,
        });
        // very important to clone object, and dont let it by reference,
        const listviewFieldsNew = JSON.parse(
          JSON.stringify(pagesSettings[0].fields)
        );
        this.updatePageSettingsFields(listviewFieldsNew); // changes fields to default, first elements
      }
      if (action === 'afterSaveUpdate') {
        pagesSettings[rowFound].fields = values.fields;
        this.setState({ pagesSettings });
      }
    }

    if (action === 'addInit') {
      pagesSettings.push(values);
      this.setState({ pagesSettings, pageSettingId: '' });
    }
    if (action === 'cancelAdd') {
      pagesSettings.splice(pagesSettings.length - 1, 1);
      // restore to default
      const listviewFieldsNew = JSON.parse(
        JSON.stringify(pagesSettings[0].fields)
      );
      this.setState({
        pagesSettings,
        pageSettingId: '',
        listviewFields: listviewFieldsNew,
      });
    }

    if (action === 'addAfterSave') {
      // add, but not for default template, the state row alredy was inserted
      const pageRow = pagesSettings.length - 1;
      pagesSettings[pageRow].id = values.id;
      pagesSettings[pageRow].name = values.name;
      pagesSettings[pageRow].fields = values.fields;
      pagesSettings.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );

      this.setState({ pagesSettings, pageSettingId: values.id });
    }
  };
  updatePageSettingsFields = (e, isAdding = false) => {
    if (e.target) {
      let listviewFields = this.state.listviewFields;
      listviewFields[e.target.name].invisible = this.state.listviewFields[
        e.target.name
      ].invisible
        ? false
        : true;
      this.setState({
        listviewFields,
      });
    } else {
      this.setState({
        listviewFields: e,
      });
    }
  };

  componentDidMount() {
    const intervalId = setInterval(this.timer, 4000);
    this.setState({ intervalId });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  timer = () => {
    if (localStorage.getItem('Error') !== null) {
      this.setState({ date: Date.now() });
      clearInterval(this.state.intervalId);
    }
    if (Tables[tableCrud].pollInterval) {
      this.props[qlName].refetch();
    }
  };

  hocdebounced = debounce((methodCode, params) => {
    this.executeCode(methodCode, params);
  }, 1500);

  executeCode = async (methodCode = '', params = {}) => {
    params.tableCrud = tableCrud;
    params.formState = this.state;

    if (methodCode === 'onChangeInput' && params.action !== 'initForm') {
      const { inputFullName, line, action, event, newValue } = params;
      const oldValue = getInputValue(this.props, inputFullName, {
        nameForm: formFilterName,
      });
      if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
        this.props.dispatch(change(formPagerName, '_page', 1));
      }
    }

    if (!crudCode[methodCode]) {
      return;
    }

    const result = await crudCode[methodCode](params);

    if (result && result.changeFieldsLater) {
      Object.keys(result.changeFieldsLater).map((keyName, keyIndex) => {
        let valueField = result.changeFieldsLater[keyName];
        params.formProps.change(keyName, valueField);
      });
    }

    if (result && result.newStates) {
      let formStateChanged = false;
      let currentState = this.state;
      Object.keys(result.newStates).map((keyName, keyIndex) => {
        if (
          typeof this.state[keyName] === 'undefined' ||
          JSON.stringify(this.state[keyName]) !==
            JSON.stringify(result.newStates[keyName])
        ) {
          let keyNewState = result.newStates[keyName];

          if (keyName === 'warningFields') {
            keyNewState = {
              ...this.state[keyName],
              ...result.newStates[keyName],
            };
          }
          this.setState({ [keyName]: keyNewState });
          currentState[keyName] = result.newStates[keyName];
          formStateChanged = true;
        }
      });
      if (formStateChanged) {
        this.props.dispatch(
          change(nameForm, '_formstate', JSON.stringify(currentState))
        );
      }
    }

    if (result && typeof result.valueToReturn !== 'undefined') {
      return result.valueToReturn;
    }
  };

  toolbarFunctions = (toolbar, params = {}) => {
    if (toolbar === 'email') {
      //console.log('tour_id', tour_id);

      this.setState({
        dialogType: 'CUS', //  means email
        dialogOpened: { ...this.state.dialogOpened, MailCompose: true },
        defaultValues: {
          customer_id: null,
          tour_id: null,
        },
      });
    }

    if (toolbar === 'labels') {
      this.setState({
        dialogOpened: { ...this.state.dialogOpened, Labels: true },
        defaultValues: {},
      });
    }
    if (toolbar === 'closeDialog') {
      let dialogOpenedNew = this.state.dialogOpened;
      if (dialogOpenedNew[params.dialogName]) {
        delete dialogOpenedNew[params.dialogName];
        this.setState({ dialogOpened: dialogOpenedNew });
      }
    }

    if (toolbar === 'filterregistration') {
      this.props.goUrl('', {
        callback: () => {
          let existForm = getInputValue(this.props, '*', {
            nameForm: 'listFilterregistration',
          });
          if (existForm) {
            cleanFilters('registration', this.props.dispatch, change);
            this.props.dispatch(
              change('listFilterregistration', 'customer_id', {
                id: params.id,
                name: params.name,
              })
            );
            this.props.history.push('/registration');
          } else {
            const name = getInputValue(this.props, 'name', {
              nameForm: 'customerForm',
            });
            this.props.history.push(
              '/registration?customer_id=' +
                params.id +
                '&customer_name=' +
                params.name
            );
          }
        },
      });
    }

    if (toolbar === 'filterpayment') {
      this.props.goUrl('', {
        callback: () => {
          let existForm = getInputValue(this.props, '*', {
            nameForm: 'listFilterpayment',
          });

          if (existForm) {
            cleanFilters('payment', this.props.dispatch, change);

            this.props.dispatch(
              change('listFilterpayment', 'application_customer_id', {
                id: params.id,
                name: params.name,
              })
            );
            this.props.history.push('/payment');
          } else {
            this.props.history.push(
              '/payment?application_customer_id=' +
                params.id +
                '&application_customer_name=' +
                params.name
            );
          }
        },
      });
    }
  };

  visibleFieldsToggle = () => {
    this.setState({
      visibleListViewFields: !this.state.visibleListViewFields,
    });
  };

  navPage = (navTo, page) => {
    let newPage = parseInt('0' + page._page);
    if (navTo === 'right' && page._page < page._totalpages) {
      newPage++;
    }
    if (navTo === 'left' && page._page > 1) {
      newPage--;
    }
    if (navTo === 'first') {
      newPage = 1;
    }
    if (navTo === 'last') {
      newPage = page._totalpages;
    }
    let strNewpage = parseInt('0' + newPage.toString());
    this.props.change('_page', strNewpage);
  };

  render() {
    const { t, ...otherProps } = this.props;
    let aElements = [];
    let aQlFiltered = {
      crud_list_customer: { table: 'customer' },
      list_customeraddress: { table: 'customeraddress' },
      list_gendertype: { table: 'gendertype' },
      list_customerstatus: { table: 'customerstatus' },
    };
    const resultCheck = checkLoadCrud(aQlFiltered, this.props);
    if (resultCheck.messageError) {
      return <MsgError msg={resultCheck.messageError} t={this.props.t} />;
    }
    aElements.push(
      <PageHeader
        key="pageheader"
        action={action}
        t={t}
        tableCrud={tableCrud}
        tables={Tables}
      />
    );
    if (this.state.dialogOpened.MailCompose)
      aElements.push(
        <MailCompose
          key="MailCompose"
          target="customer"
          {...this.props}
          containerState={this.state}
          toolbarFunctionsContainer={this.toolbarFunctions}
        />
      );
    if (this.state.dialogOpened.Labels)
      aElements.push(
        <Labels
          key="Labels"
          {...this.props}
          containerState={this.state}
          toolbarFunctionsContainer={this.toolbarFunctions}
        />
      );
    aElements.push(
      <CrudToolBar
        containerPropsForm={this.props}
        toolbarFunctions={this.toolbarFunctions}
        handleSubmit={this.handleSubmit}
        handleDeleteRecord={this.handleDeleteRecord}
        t={this.props.t}
        tableCrud={tableCrud}
        containerState={this.state}
        nameCrudForm={nameForm}
        nameFilterForm={formFilterName}
        tables={Tables}
        key="crudToolBar1"
        crudCode={crudCode}
        executeCode={this.executeCode}
      />
    );
    aElements.push(
      <CrudFilterOrderBar
        containerPropsForm={this.props}
        toolbarFunctions={this.toolbarFunctions}
        handleSubmit={this.handleSubmit}
        handleDeleteRecord={this.handleDeleteRecord}
        t={this.props.t}
        tableCrud={tableCrud}
        containerState={this.state}
        nameCrudForm={nameForm}
        nameFilterForm={formFilterName}
        tables={Tables}
        key="crudFilter"
        executeCode={this.executeCode}
        templates={templates}
      />
    );
    let result;
    let recordsLoaded;

    if (
      !resultCheck.globalLoading &&
      !resultCheck.messageError &&
      this.props[qlName] &&
      this.props[qlName].pagecustomers
    ) {
      recordsLoaded = true;
      result = this.props[qlName].pagecustomers;
    }
    // new
    aElements.push(
      <form key="listForm">
        <Field key="_page" name="_page" component="input" type="hidden" />
        <Field
          key="_settings"
          name="_settings"
          component="input"
          type="hidden"
        />
      </form>
    );

    if (resultCheck.globalLoading && !resultCheck.messageError) {
      aElements.push(<IconLoading key="load" />);
    }

    if (recordsLoaded) {
      aElements.push(
        <CrudNav
          key="crudnav1"
          t={t}
          page={result.page}
          navPage={this.navPage}
        />
      );

      aElements.push(
        <PageSettings
          updatePageSetting={this.updatePageSetting}
          key="PageSettings"
          t={t}
          showNotificationWithTimeout={this.props.showNotificationWithTimeout}
          deletePagesetting={this.props.deletePagesetting}
          showConfirm={this.props.showConfirm}
          pagesSettings={this.state.pagesSettings}
          isVisiblePageSettings={this.state.visibleListViewFields}
          templates={templates}
          updatePageSettingsFields={this.updatePageSettingsFields}
          table={Tables[tableCrud]}
          listviewFields={this.state.listviewFields}
          tableCrud={tableCrud}
          visibleFieldsToggle={this.visibleFieldsToggle}
          pageSettingId={this.state.pageSettingId}
          onChangePageSettingId={this.onChangePageSettingId}
        />
      );

      aElements.push(
        <ListDataHeader
          key="list"
          myState={this.props.myState}
          t={t}
          tableCrud={tableCrud}
          settings={this.state}
          refresh={
            !Tables[tableCrud].pollInterval && this.props[qlName]
              ? this.props[qlName].refetch
              : false
          }
        />
      );

      for (let r = 0; r < result.nodes.length; r++) {
        let record = result.nodes[r];
        let nextrecord =
          table.listSendNextRecord && r + 1 < result.nodes.length
            ? result.nodes[r + 1]
            : {};
        let item =
          (result.page._page - 1) * result.page._recordsperpage + r + 1;

        aElements.push(
          <ListDataRercord
            key={record.id}
            myState={this.props.myState}
            t={t}
            item={item}
            toolbarFunctions={this.toolbarFunctions}
            tableCrud={tableCrud}
            record={record}
            nextrecord={nextrecord}
            settings={this.state}
          />
        );
      }

      if (result.page._totalpages > 1) {
        aElements.push(
          <CrudNav
            key="crudnav2"
            t={t}
            page={result.page}
            navPage={this.navPage}
          />
        );
      } else {
        aElements.push(
          <div className="xrow" key="rfound">
            <div className="cell"></div>
            <div
              className="cell"
              style={{ color: '#999999', paddingTop: '20px' }}
            >
              {result.page._totalrecords} {t('info.recordsFound')}
            </div>
          </div>
        );
      }
    }

    return <div>{aElements}</div>;
  }
}

const ComponentWithData = reduxForm({
  form: formPagerName,
  enableReinitialize: true,
})(List);

const withRouterList = withRouter(ComponentWithData);
const withGraphqlandRouter = compose(
  // graphql(defQls.customer.ListPage, {
  graphql(QUERY, {
    // new
    name: 'crud_list_customer',
    options: (props) => {
      let filters = getFilters(tableCrud, props, formPagerName, formFilterName);
      const optionsValues = { variables: { ...filters, _qlType: 'ListPage' } };
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      return optionsValues;
    },
    skip: (props) => {
      return !(
        props.myState &&
        props.myState.form &&
        props.myState.form[formFilterName] &&
        props.myState.form[tableCrud + 'Pager'] &&
        props.myState.form[formFilterName].values &&
        props.myState.form[tableCrud + 'Pager'].values
      );
    },
  }),

  graphql(defQls.deletePagesetting, {
    name: 'deletePagesetting',
    options: (props) => {
      const optionsValues = { variables: { id: props.id } };
      optionsValues.fetchPolicy = 'no-cache';
      return optionsValues;
    },
  }),
  graphql(defQls.customeraddress.ListMini, {
    name: 'list_customeraddress',
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      optionsValues.variables = { _qlType: 'ListMini' };
      return optionsValues;
    },
  }),
  graphql(defQls.gendertype.ListMini, {
    name: 'list_gendertype',
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      optionsValues.variables = { _qlType: 'ListMini' };
      return optionsValues;
    },
  }),
  graphql(defQls.customerstatus.ListMini, {
    name: 'list_customerstatus',
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      optionsValues.variables = { _qlType: 'ListMini' };
      return optionsValues;
    },
  })
)(withRouterList);

function mapStateToProps(state, ownProps) {
  let initialValues = { _page: 1 };
  const statesReturn = { myState: state };
  if (
    state &&
    state.form &&
    state.form[formPagerName] &&
    typeof state.form[formPagerName].values
  ) {
    statesReturn.initialValues = initialValues;
  }
  return statesReturn;
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { change, showConfirm, hideConfirm, dispatch },
    dispatch
  );
};

const ComponentWithDataAndState = connect(
  mapStateToProps,
  mapDispatchToProps
)(withGraphqlandRouter);

export default ComponentWithDataAndState;
