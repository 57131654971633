const strFragments = {};
strFragments['organizer'] = `  id
  name
  province
  contact
  email
  limitemail
  limitsms
  info
  status
  contacts
  modules
  numberingformat
  notes
  settingsforms
  textgcu
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizerupdated_user_id  {
    name
 }
  organizercreated_user_id  {
    name
 }
`;
strFragments['customeraddress'] = `  id
  customers
  addresses
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  customeraddressupdated_user_id  {
    name
 }
  customeraddresscreated_user_id  {
    name
 }
`;
strFragments['person'] = `  id
  gendertype_id
  name
  lastname
  firstname
  email
  nick
  password
  socialsn
  imageright
  inactive
  deceased
  birthdate
  grouped_customer_id
  grouped_filiation
  info
  nationality
  residence
  contacts
  phones
  groupedaddress_customer_id
  customeraddress_customers
  addresses
  identitydocs
  counterhistory
  person_groupid
  pending_tourid
  member
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  persongrouped_customer_id  {
    name
 }
  persongroupedaddress_customer_id  {
    name
 }
  persongendertype_id  {
    name
 }
  personpending_tourid  {
    name
 }
  personupdated_user_id  {
    name
 }
  personcreated_user_id  {
    name
 }
`;
strFragments['customer'] = `  id
  gendertype_id
  name
  lastname
  firstname
  numbering
  email
  socialsn
  imageright
  nosharedata
  nonewsletter
  inactive
  deceased
  customerstatus_id
  customersubstatus_id
  dietnotes
  handicapnotes
  grouped_customer_id
  grouped_filiation
  birthdate
  info
  nationality
  residence
  contacts
  mobile
  phones
  customeraddress_id
  customeraddress_customers
  addresses
  identitydocs
  counterhistory
  person_id
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  customercustomeraddress_id  {
    customers
    addresses
 }
  customergrouped_customer_id  {
    name
 }
  customergendertype_id  {
    name
 }
  customercustomerstatus_id  {
    name
 }
  customerperson_id  {
    name
    lastname
    firstname
    email
    nick
    password
    socialsn
    info
    imageright
    inactive
    deceased
    birthdate
    grouped_filiation
    info
    nationality
    residence
    contacts
    phones
    groupedaddress_customer_id
    addresses
    identitydocs
    counterhistory
    person_groupid
    pending_tourid
    member
    deleted
 }
  customerupdated_user_id  {
    name
 }
  customercreated_user_id  {
    name
 }
`;
strFragments['tour'] = `  id
  name
  longname
  country
  description
  seatsmax
  seatsmin
  datestart
  dateend
  inactive
  registrationstart
  registrationend
  tourcategory_id
  onlineregtype
  gcu
  urlgcu
  textgcu
  itinerary
  total
  paid
  balance
  price
  cancelledprice
  acode_id
  acostcode_id
  servicesbystatus
  additionalservices
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  tourtourcategory_id  {
    name
 }
  touracode_id  {
    name
 }
  touracostcode_id  {
    name
 }
  tourupdated_user_id  {
    name
 }
  tourcreated_user_id  {
    name
 }
`;
strFragments['tourcategory'] = `  id
  name
  settings
  filterbycustomerstatus
  filteredcustomerstatuss
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  tourcategoryupdated_user_id  {
    name
 }
  tourcategorycreated_user_id  {
    name
 }
`;
strFragments['transportation'] = `  id
  tour_id
  name
  nameonly
  seats
  seats_allocated
  seats_available
  price
  driverinfo
  responsibleinfo
  routeplanner
  seatingconfiguration
  bookings
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  transportationtour_id  {
    name
    seatsmax
 }
  transportationupdated_user_id  {
    name
 }
  transportationcreated_user_id  {
    name
 }
`;
strFragments['tourprice'] = `  id
  customerstatus_id
  tour_id
  price
  substatuss
  notes
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  tourpricecustomerstatus_id  {
    name
 }
  tourpricetour_id  {
    name
    seatsmax
 }
  tourpriceupdated_user_id  {
    name
 }
  tourpricecreated_user_id  {
    name
 }
`;
strFragments['tourroom'] = `  id
  hotel_id
  room_type
  name
  tour_id
  _name
  isfullname
  room_quantity
  people_capacity
  people_registered
  people_pre_registered
  people_available
  people_allocated
  people_to_allocate
  priceroom
  bookings
  customers
  room_numbers
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  tourroomtour_id  {
    name
 }
  tourroomhotel_id  {
    name
 }
  tourroomupdated_user_id  {
    name
 }
  tourroomcreated_user_id  {
    name
 }
`;
strFragments['regonline'] = `  id
  customer_id
  person_id
  tour_id
  registration_id
  customerstatus_id
  customersubstatus_id
  dateregistration
  tourroom_id
  room_type
  room_shared
  stopspoint_id
  items
  objects
  price
  partialpayment
  registrationstatus_id
  signature
  paid
  payinput
  payoutput
  paymethod
  state
  notes
  person_groupid
  modetest
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  regonlineperson_id  {
    name
    email
    id
 }
  regonlineregistration_id  {
    registrationstatus_id
    id
 }
  regonlinecustomer_id  {
    name
    email
    id
 }
  regonlinetour_id  {
    name
    seatsmax
    id
 }
  regonlinetourroom_id  {
    room_type
    hotel_id
  tourroomhotel_id  {
    name
 }
    id
 }
  regonlineregistrationstatus_id  {
    name
    color
    statusbase
    id
 }
  regonlinestopspoint_id  {
    name
    id
 }
  regonlinecustomerstatus_id  {
    name
    id
 }
  regonlineupdated_user_id  {
    name
    id
 }
  regonlinecreated_user_id  {
    name
    id
 }
`;
strFragments['registration'] = `  id
  customer_id
  registrationgroupping_customers
  regonline_id
  person_id
  tour_id
  _services
  numbering
  registrationstatus_id
  dateregistration
  confirmationstatus_id
  inactive
  customerstatus_id
  customersubstatus_id
  tourroom_id
  room_shared
  assignedroom
  _roommates
  tollfree
  price
  paid
  balance
  _priceformula
  transportation
  objects
  notes
  counterhistory
  person_groupid
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  registrationcustomer_id  {
    name
    email
 }
  registrationtour_id  {
    name
    seatsmax
 }
  registrationregonline_id  {
    person_id
    registration_id
    customersubstatus_id
    dateregistration
    room_type
    room_shared
    stopspoint_id
    items
    objects
    price
    partialpayment
    signature
    paid
    payinput
    payoutput
    paymethod
    state
    notes
    person_groupid
    modetest
    deleted
 }
  registrationtourroom_id  {
    room_type
    hotel_id
  tourroomhotel_id  {
    name
 }
 }
  registrationregistrationstatus_id  {
    name
    color
    statusbase
 }
  registrationconfirmationstatus_id  {
    name
    color
 }
  registrationcustomerstatus_id  {
    name
 }
  registrationupdated_user_id  {
    name
 }
  registrationcreated_user_id  {
    name
 }
`;
strFragments['customerhistory'] = `  id
  actiondate
  user_id
  customer_id
  changes
  oldchanges
  customerhistoryuser_id  {
    name
 }
  customerhistorycustomer_id  {
    name
 }
`;
strFragments['paymenthistory'] = `  id
  actiondate
  user_id
  payment_id
  changes
  oldchanges
  paymenthistoryuser_id  {
    name
 }
  paymenthistorypayment_id  {
    name
 }
`;
strFragments['registrationhistory'] = `  id
  actiondate
  user_id
  customer_id
  tour_id
  tourroom_id
  dateregistration
  registrationstatus_id
  confirmationstatus_id
  notes
  registration_id
  changes
  oldchanges
  registrationhistoryuser_id  {
    name
 }
  registrationhistorycustomer_id  {
    name
 }
  registrationhistorytour_id  {
    name
    seatsmax
 }
  registrationhistorytourroom_id  {
    name
 }
  registrationhistoryregistrationstatus_id  {
    name
    color
    statusbase
 }
  registrationhistoryconfirmationstatus_id  {
    name
    color
 }
  registrationhistoryregistration_id  {
    registrationstatus_id
 }
`;
strFragments['payment'] = `  id
  name
  paymentmethod_id
  amount
  datereception
  datedeferred
  dateaccounting
  bank_id
  paymentdocstatus_id
  application
  aentry
  balance
  info
  checkdeposit_id
  counterhistory
  person_groupid
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  paymentbank_id  {
    name
 }
  paymentpaymentmethod_id  {
    name
    color
    statusbase
    usedatedeferred
 }
  paymentcheckdeposit_id  {
    name
 }
  paymentpaymentdocstatus_id  {
    name
    color
    statusbase
 }
  paymentupdated_user_id  {
    name
 }
  paymentcreated_user_id  {
    name
 }
`;
strFragments['checkdeposithead'] = `  id
  name
  statusbase
  date
  dateaccounting
  info
  lastcheckdepositopen
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  checkdepositheadupdated_user_id  {
    name
 }
  checkdepositheadcreated_user_id  {
    name
 }
`;
strFragments['checkdepositdet'] = `  id
  payment_id
  checkdeposithead_id
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  checkdepositdetpayment_id  {
    name
    datereception
    datedeferred
    dateaccounting
    bank_id
  paymentbank_id  {
    name
 }
    info
    amount
    application
 }
  checkdepositdetcheckdeposithead_id  {
    name
 }
  checkdepositdetupdated_user_id  {
    name
 }
  checkdepositdetcreated_user_id  {
    name
 }
`;
strFragments['export'] = `  id
  name
  state
  exportdate
  type
  files
  registration_fields
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  exportupdated_user_id  {
    name
 }
  exportcreated_user_id  {
    name
 }
`;
strFragments['hotel'] = `  id
  name
  longname
  email
  phone
  tour_id
  info
  contacts
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  hoteltour_id  {
    name
 }
  hotelupdated_user_id  {
    name
 }
  hotelcreated_user_id  {
    name
 }
`;
strFragments['stopspoint'] = `  id
  name
  sinfo
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  stopspointupdated_user_id  {
    name
 }
  stopspointcreated_user_id  {
    name
 }
`;
strFragments['service'] = `  id
  name
  statusbase
  nameinregistration
  filterlist
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  serviceupdated_user_id  {
    name
 }
  servicecreated_user_id  {
    name
 }
`;
strFragments['city'] = `  id
  name
  pcs
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  cityupdated_user_id  {
    name
 }
  citycreated_user_id  {
    name
 }
`;
strFragments['registrationstatus'] = `  id
  name
  statusbase
  color
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  registrationstatusupdated_user_id  {
    name
 }
  registrationstatuscreated_user_id  {
    name
 }
`;
strFragments['confirmationstatus'] = `  id
  name
  statusbase
  color
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  confirmationstatusupdated_user_id  {
    name
 }
  confirmationstatuscreated_user_id  {
    name
 }
`;
strFragments['bank'] = `  id
  name
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  bankupdated_user_id  {
    name
 }
  bankcreated_user_id  {
    name
 }
`;
strFragments['paymentmethod'] = `  id
  name
  statusbase
  color
  usedatedeferred
  ajournalcode_id
  anature
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  paymentmethodajournalcode_id  {
    name
    title
    counterpart_id
  ajournalcodecounterpart_id  {
    name
 }
 }
  paymentmethodupdated_user_id  {
    name
 }
  paymentmethodcreated_user_id  {
    name
 }
`;
strFragments['paymentdocstatus'] = `  id
  name
  statusbase
  color
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  paymentdocstatusupdated_user_id  {
    name
 }
  paymentdocstatuscreated_user_id  {
    name
 }
`;
strFragments['customerstatus'] = `  id
  name
  longname
  substatus
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  customerstatusupdated_user_id  {
    name
 }
  customerstatuscreated_user_id  {
    name
 }
`;
strFragments['gendertype'] = `  id
  name
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  gendertypeupdated_user_id  {
    name
 }
  gendertypecreated_user_id  {
    name
 }
`;
strFragments['userlog'] = `  id
  name
  actiondate
  user_id
  record_id
  record_parentid
  record_table
  action
  ip
  userloguser_id  {
    name
 }
`;
strFragments['user'] = `  id
  nick
  password
  name
  longname
  email
  isadmin
  usergroup_id
  lastaccessdate
  lastactiondate
  countactionsmonth
  phones
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  userusergroup_id  {
    name
 }
`;
strFragments['usergroup'] = `  id
  name
  accessrestrictedto
  permissions
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  usergroupupdated_user_id  {
    name
 }
  usergroupcreated_user_id  {
    name
 }
`;
strFragments['ejobmail'] = `  id
  customer_id
  type_template
  channels
  channelsfilters
  withEmail
  withMobile
  etemplate_id
  title
  tour_id
  limitexceeded
  totalMessaging
  confirmationstatus_id
  state
  customersrecipients
  files
  attachfiles
  templatedata
  channel
  sent
  templatesms
  sms
  template
  body
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  ejobmailcustomer_id  {
    name
 }
  ejobmailtour_id  {
    name
 }
  ejobmailetemplate_id  {
    name
 }
  ejobmailupdated_user_id  {
    name
 }
  ejobmailcreated_user_id  {
    name
 }
`;
strFragments['etemplate'] = `  id
  name
  channel
  type
  title
  body
  sms
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  etemplateupdated_user_id  {
    name
 }
  etemplatecreated_user_id  {
    name
 }
`;
strFragments['ejobday'] = `  id
  name
  qty
  qtysms
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  ejobdayupdated_user_id  {
    name
 }
  ejobdaycreated_user_id  {
    name
 }
`;
strFragments['ejobmonth'] = `  id
  name
  qty
  qtysms
  amount
  paid
  balance
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  ejobmonthupdated_user_id  {
    name
 }
  ejobmonthcreated_user_id  {
    name
 }
`;
strFragments['aplan'] = `  id
  name
  title
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  aplanupdated_user_id  {
    name
 }
  aplancreated_user_id  {
    name
 }
`;
strFragments['acostplan'] = `  id
  name
  title
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  acostplanupdated_user_id  {
    name
 }
  acostplancreated_user_id  {
    name
 }
`;
strFragments['ajournalcode'] = `  id
  name
  title
  counterpart_id
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  ajournalcodecounterpart_id  {
    name
 }
  ajournalcodeupdated_user_id  {
    name
 }
  ajournalcodecreated_user_id  {
    name
 }
`;

export default strFragments;
